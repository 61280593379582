import { Card, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import PointIcon from '../../../../assets/icons/PointIcon';
import React from 'react';
import colors from '../../../../style/colors';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  memberCard: {
    padding: 16,
    boxShadow: 'none',
    borderRadius: 5,
    textAlign: 'left',
    border: '1px solid',
    borderColor: colors.grey,
    marginBottom: 24,
  },
  name: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 2,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 10,
    paddingRight: 8,
    lineHeight: 2,
  },
  data: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
  },
  pointIcon: {
    fontSize: 16,
    marginRight: 5,
  },
  verticalDivider: {
    padding: '0px 16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default function MemberCard(props) {
  const { data, tierStyle } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { lang, program } = useStore();

  if (!data) return <></>;

  return (
    <Card
      className={classes.memberCard}
      style={{ backgroundColor: tierStyle.backgroundColor }}
    >
      <span className={classes.name} style={{ color: tierStyle.color }}>{`${
        data?.first_name
      } ${data?.last_name}`}</span>
      <div className={classes.row}>
        <span className={classes.subtitle} style={{ color: tierStyle.color }}>
          {program?.default_point_scheme.title[lang]}：
        </span>
        <PointIcon
          className={classes.pointIcon}
          style={{ color: tierStyle.color }}
        />
        <span className={classes.data} style={{ color: tierStyle.color }}>
          {data?.point?.amount}
        </span>
        {program?.credit_point_scheme && (
          <>
            <span
              className={classes.verticalDivider}
              style={{ color: tierStyle.color }}
            >
              |
            </span>
            <span
              className={classes.subtitle}
              style={{ color: tierStyle.color }}
            >
              {program?.credit_point_scheme.title[lang]}：
            </span>
            <span className={classes.data} style={{ color: tierStyle.color }}>
              {data?.credit?.amount}
            </span>{' '}
          </>
        )}
      </div>
    </Card>
  );
}
