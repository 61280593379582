import { Button, Card, Collapse, Divider, makeStyles } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import HTMLString from 'react-html-string';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import PointIcon from '../../../../assets/icons/PointIcon';
import React, { useState } from 'react';
import colors from '../../../../style/colors';
import styled from '@emotion/styled';
import useStore from '../../../../hooks/useStore';

const useStyles = makeStyles({
  memberCard: {
    padding: 16,
    boxShadow: 'none',
    borderRadius: 5,
    textAlign: 'left',
    border: '1px solid',
    borderColor: colors.grey,
  },
  name: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 2,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 10,
    paddingRight: 8,
    lineHeight: 2,
  },
  data: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
  },
  pointIcon: {
    fontSize: 16,
    marginRight: 5,
  },
  infoList: {
    paddingInlineStart: 16,
  },
  verticalDivider: {
    padding: '0px 16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    marginBottom: 16,
  },
  textButton: {
    padding: 0,
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  skeleton: {
    marginBottom: 8,
    marginTop: 8,
  },
});

export default function MemberCard(props) {
  const { data, isLoading, tierStyle } = props;
  const classes = useStyles();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const { t } = useTranslation();
  const { lang, program } = useStore();

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 5,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 16,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.progressBar,
      filter: 'contrast(300%)',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: tierStyle?.color ?? colors.white,
    },
  }));

  const getTierProgress = () => {
    const current = data?.tier?.mission?.progress?.current;
    const target = data?.tier?.mission?.progress?.target;
    if (current && target) {
      return (current / target) * 100;
    }
    return 0;
  };

  const infoList = [];

  data?.info?.forEach((info) => {
    if (info[lang]) {
      infoList.push(info[lang]);
    }
  });

  return (
    <Card
      className={classes.memberCard}
      style={{ backgroundColor: tierStyle?.backgroundColor }}
    >
      {!isLoading && (
        <>
          <span
            className={classes.name}
            style={{ color: tierStyle?.color }}
          >{`${data?.first_name} ${data?.last_name}`}</span>
          <div className={classes.row}>
            <span
              className={classes.subtitle}
              style={{ color: tierStyle?.color }}
            >
              {program?.default_point_scheme.title[lang]}：
            </span>
            <PointIcon
              className={classes.pointIcon}
              style={{ color: tierStyle?.color }}
            />
            <span className={classes.data} style={{ color: tierStyle?.color }}>
              {data?.point?.amount}
            </span>
            {program?.credit_point_scheme && (
              <>
                <span
                  className={classes.verticalDivider}
                  style={{ color: tierStyle?.color }}
                >
                  |
                </span>
                <span
                  className={classes.subtitle}
                  style={{ color: tierStyle?.color }}
                >
                  {program?.credit_point_scheme.title[lang]}：
                </span>
                <span
                  className={classes.data}
                  style={{ color: tierStyle?.color }}
                >
                  {data?.credit?.amount}
                </span>
              </>
            )}
          </div>
          {data?.tier && (
            <>
              <div className={classes.row}>
                <span
                  className={classes.subtitle}
                  style={{ color: tierStyle?.color }}
                >
                  {t('home.tier')}
                </span>
                <span
                  className={classes.data}
                  style={{ color: tierStyle?.color }}
                >
                  {data?.tier?.title[lang]}
                </span>
              </div>
              {data?.tier?.mission?.progress && (
                <BorderLinearProgress
                  variant="determinate"
                  value={getTierProgress()}
                />
              )}
            </>
          )}
          {infoList.length > 0 && (
            <Collapse in={isInfoOpen}>
              <Divider className={classes.divider} />
              <ul className={classes.infoList}>
                {infoList.map((info, index) => {
                  return (
                    <li
                      className={classes.info}
                      key={index}
                      style={{ color: tierStyle?.color }}
                    >
                      <HTMLString html={info} />
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          )}
          {infoList.length > 0 && isInfoOpen === false && (
            <Button
              variant="text"
              className={classes.textButton}
              onClick={() => setIsInfoOpen(true)}
            >
              <span
                className={classes.buttonText}
                style={{ color: tierStyle?.color }}
              >
                {t('home.moreInfo')}
              </span>
              <ExpandMoreOutlinedIcon
                fontSize="small"
                className={classes.buttonText}
                style={{ color: tierStyle?.color }}
              />
            </Button>
          )}
          {infoList.length > 0 && isInfoOpen === true && (
            <Button
              variant="text"
              className={classes.textButton}
              onClick={() => setIsInfoOpen(false)}
            >
              <span
                className={classes.buttonText}
                style={{ color: tierStyle?.color }}
              >
                {t('home.hideInfo')}
              </span>
              <ExpandLessOutlinedIcon
                fontSize="small"
                className={classes.buttonText}
                style={{ color: tierStyle?.color }}
              />
            </Button>
          )}
        </>
      )}
      {isLoading && (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={16}
            width={100}
            className={classes.skeleton}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={52}
            className={classes.skeleton}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={10}
            width={100}
            className={classes.skeleton}
          />
        </>
      )}
    </Card>
  );
}
